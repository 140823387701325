import { ContractsApi, DealBuilderApi, DocumentVaultAPI, CodesApi, ClientsApi } from "@unity/components";

async function getBusinesses(props) {
    const res = await CodesApi.getBusinessUnits();
    if (res.success) {
        props.change(res.data);
    }
}

async function getClients(props) {
    const res = await ClientsApi.clientsAllRelationsSelect();
    if (res.success) {
        props.change(res.data);
    }
}

async function getDeals(props) {
    const res = await DealBuilderApi.getActiveDeals({ read_all: true, pagination: false });
    if (res.success) {
        props.change(res.data);
    }
}

async function getContract(props) {
    const res = await ContractsApi.getContractSingle(props.id);
    if(res.success) {
        props.change(res.data);
    }
};

async function getVariation(props) {
    const res = await ContractsApi.getVariationSingle(props.id);
    if(res.success) {
        props.change(res.data);
    }
};

async function getChargeCodes(props) {
    const res = await CodesApi.getChargeCodes();
    if (res.success) {
        props.change(res.data);
    }
};

async function getPeriodicCodes(props) {
    const res = await CodesApi.getPeriodicCodes();
    if (res.success) {
        props.change(res.data);
    }
};

async function getPaymentMethods(props) {
    const res = await CodesApi.getPaymentMethods();
    if (res.success) {
        props.change(res.data);
    }
};

async function getVatRates(props) {
    const res = await CodesApi.getVatRates();
    if (res.success) {
        props.vatsChange(res.data);
    }
};

async function getEnquiryTypes(props) {
    const res = await ContractsApi.getEnquiryTypes();
    if (res.success) {
        props.change(res.data);
    }
};

async function createVariation(props) {
    const newData = {};

    const res = await ContractsApi.getContractSingle(props.id);

    console.log("CREATE VAR",res);

    if(res.success) {
        newData['contract_id'] = props.id;
        newData['contract_ref'] = res.data.ref;
        newData['company_reg'] = res.data.company_reg;
        newData['customer_name'] = res.data.customer_name;
        newData['customer_tel'] = res.data.customer_tel;
        newData['customer_email'] = res.data.customer_email;
        newData['billing_email'] = res.data.billing_email;
        newData['site_id'] = res.data.site_id;
        newData['site_name'] = res.data.site_name;
        newData['contact_id'] = res.data.contact_id;
        newData['contact_name'] = res.data.contact_name;
        newData['end_date'] = res.data.end_date;
        newData['services'] = res.data.services;
        newData['index'] = res.data.variation_index;

        props.change(newData);
    }
};

async function handleView(props) {
    let id = props.contract.signed ? props.contract.signed_pdf_id : props.contract.generated_pdf_id;
    const res = await DocumentVaultAPI.generateTempLink(id);
    window.open(res.data, "_blank");
};

function handleChange(props) {
    const newData = {};
    
    newData['changed'] = true;
    newData[props.name] = props.value;

    props.change(newData);
};

function handleClientChange(props) {
    const newData = {};

    newData['changed'] = true;
    newData["customer_id"] = props.client.client_id;
    newData["customer_name"] = props.client.client_name;
    newData["account_no"] = props.client.account_no;
    newData["company_reg"] = props.client.company_no;
    newData["billing_email"] = props.client.billing_email;

    props.change(newData);
    props.contactsChange(props.client.contacts);
    props.sitesChange(props.client.sites);
};

function handlePersonChange(props) {
    const newData = {};

    newData['changed'] = true;
    newData[props.event.target.name] = props.event.target.value;
    newData["contact_name"] = props.element.props.personObj.contact_name,
    newData["customer_email"] = props.element.props.personObj.contact_email;
    newData["customer_tel"] = props.element.props.personObj.contact_phone;

    props.change(newData);
};

function handleSiteChange(props) {
    const newData = {};

    newData['changed'] = true;
    newData[props.event.target.name] = props.event.target.value;
    newData["site_name"] = props.element.props.siteObj.site_name;

    props.change(newData);
};

function setClientChildren(props) {
    const clientObj = props.clients.find((e) => e.client_id == props.id);

    if(typeof clientObj !== 'undefined') {
        props.contactsChange(clientObj.contacts);
        props.sitesChange(clientObj.sites);
    }
};

async function handleAddService(props) {
    const res = await ContractsApi.saveService(props.data);

    if (res.success) {
        props.triggerChange();
    }
};

async function handleRemoveService(props) {
    const res = await ContractsApi.deleteService(props.id);

    if (res.success) {
        props.triggerChange();
    }
};

function getCodeName(props) {
    if(Array.isArray(props.codesArray)) {
        const obj = props.codesArray.find(element => element.id === props.id );

        if(typeof obj !== 'undefined') {
            return obj.name;
        }
    }
    return 'undefined';
};


export default {
    getBusinesses,
    getClients,
    getDeals,
    getContract,
    getVariation,
    getChargeCodes,
    getPeriodicCodes,
    getPaymentMethods,
    getVatRates,
    getEnquiryTypes,
    createVariation,
    handleView,
    handleChange,
    handleClientChange,
    handlePersonChange,
    handleSiteChange,
    setClientChildren,
    handleAddService,
    handleRemoveService,
    getCodeName
};