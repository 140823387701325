import React, { useEffect, useState } from "react";
import ContractForm from "../modules/ContractForm";
import { AppHeaderComponent, ApiLoaderComponent, LoadingComponent } from "@unity/components";
import { useHistory } from "react-router";
import { CanRead } from "../services/Permissions";
import ModuleFunctions from "../common/ModuleFunctions";

export default function ContractView(props) {
  const [contract, setContract] = useState(false);
  const [bus, setBus] = useState(false);
  const [charges, setCharges] = useState(false);
  const [periods, setPeriods] = useState(false);
  const [methods, setMethods] = useState(false);
  const [clients, setClients] = useState(false);
  const [contacts, setContacts] = useState(false);
  const [sites, setSites] = useState(false);
  const [deals, setDeals] = useState(false);
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState({ status: false });
  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if page needs to be re-rendered. The value doesn't matter, just the change!

  let history = useHistory();

  const view = CanRead("contracts");

  const triggerChange = () => {
      setFetchTrigger(fetchTrigger + 1);
  };

  const chargesChange = (data) => {
      setCharges(data);
  };

  const periodsChange = (data) => {
      setPeriods(data);
  };

  const methodsChange = (data) => {
      setMethods(data);
  };

  const busChange = (data) => {
    setBus(data);
  };
  const clientsChange = (data) => {
    setClients(data);
  };

  const contactsChange = (data) => {
    setContacts(data);
  };

  const sitesChange = (data) => {
    setSites(data);
  };

  const dealsChange = (data) => {
    setDeals(data);
  };

  const contractChange = (data) => {
    setContract(prevState => ({
      ...prevState,
      ...data
    }));
  };


  useEffect(() => {
    ModuleFunctions.getContract({ id: props.route.match.params.id, change: contractChange });
    ModuleFunctions.getBusinesses({ change: busChange });
    ModuleFunctions.getClients({ change: clientsChange });
    ModuleFunctions.getDeals({ change: dealsChange });
    ModuleFunctions.getChargeCodes({ change: chargesChange });
    ModuleFunctions.getPeriodicCodes({ change: periodsChange });
    ModuleFunctions.getPaymentMethods({ change: methodsChange });
  }, [fetchTrigger]);



  if (view && contract && clients) {
    return (
      <>
        <AppHeaderComponent
          context={props.context}
          theme={props.context.theme}
          name="contracts"
          subpage="view"
        />

        <div style={{ paddingTop: 1 }}>
          <ContractForm
            type="view"
            view={view}
            bus={bus}
            contract={contract}
            clients={clients}
            contacts={contacts}
            sites={sites}
            deals={deals}
            errors={errors}
            charges={charges}
            periods={periods}
            methods={methods}
            contractChange={contractChange}
            contactsChange={contactsChange}
            sitesChange={sitesChange}
            triggerChange={triggerChange}
          />
        </div>

        <ApiLoaderComponent status={loading.status} data={loading.data} />
      </>
    );
  } else {
    return <LoadingComponent />;
  }
}
