import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "../common/DataTable";
import { ContractsApi, ApiLoaderComponent, LoadingComponent } from "@unity/components";
import ModuleButton from "../common/ModuleButton";
import ModuleFunctions from "../common/ModuleFunctions";

export default function ActiveContracts({ context, create, read, edit, admin, readAll, bus }) {
    const [loading, setLoading] = useState({ status: false });
    const [data, setData] = useState(false);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pagination, setPagination] = useState(true);// Flag to switch between unpaginated and paginated data!
    const [rowCount, setRowCount] = useState(0);// The total number of rows available from the search!
    const [searchText, setSearchText] = useState('');
    const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if getDocs() needs to be called. The value doesn't matter, just the change!

    const history = useHistory();

    const getContracts = async () => {
        const res = await ContractsApi.getActiveContracts({ read_all: readAll, pagination: pagination, page: page + 1, per_page: pageSize, search_term: searchText });
        
        if (res.success) {
            setData(res.data);
            if (pagination) {
                setRowCount(res.meta.total);
            }
        } else {
            // the request has failed, load an empty array!
            setData([]);
        }
    };

    const handlePageChange = (page) => {
        setPage(page);

        if ( pagination ) {
            setFetchTrigger(fetchTrigger + 1);
        }
    };

    const handleSizeChange = (size) => {
        setPageSize(size);

        if( pagination ) {
            setFetchTrigger(fetchTrigger + 1);
        }
    };

    const handleViewClick = (event, params, path) => {
        event.preventDefault();
        history.push(`/contracts/${path}/${params.id}`);
    };

    const handleCreateClick = (event, params, path) => {
        event.preventDefault();
        history.push({
            pathname: `/contracts/${path}`,
            state: { ...params }
        });
    };

    const columns = [
        { field: "ref", headerName: "Ref#", flex: 0.1 },
        { field: "name", headerName: "Name", flex: 0.3 },
        { field: "start_date", headerName: "Start", flex: 0.1 },
        {
            field: "business_unit",
            headerName: "Business Unit",
            minWidth: 150,
            renderCell: (cellValues) => {
                if(cellValues.row.business_unit) {
                    const pc = bus.find(e => cellValues.row.business_unit === e.id)
                    return pc.name;
                } else {
                    return null;
                }
            }
        },
        {
            field: "customer_name",
            headerName: "Customer",
            minWidth: 200,
            renderCell: (cellValues) => {
                return (
                    // <Tooltip title={cellValues.row.customer_email}>
                    //     {cellValues.row.customer_name}
                    // </Tooltip>
                    cellValues.row.customer_name
                );
            }
        },
        { field: "status", headerName: "Status", flex: 0.1 },
        {
            field: "action",
            headerName: "Action",
            minWidth: 300,
            renderCell: (cellValues) => {
                return (
                    <div>
                        <ModuleButton
                            btnIcon="visibility"
                            style={{
                                background: context.theme.sidebar.background,
                                color: "white"
                            }}
                            onClick={(event) => handleViewClick(event, cellValues.row, "view")}
                            tip="View contract"
                        />
                        <ModuleButton
                            btnIcon="cloud_download"
                            style={{
                                background: context.theme.sidebar.background,
                                color: "white",
                                marginLeft: 10
                            }}
                            onClick={() => ModuleFunctions.handleView({ contract: cellValues.row })}
                            tip="Download original signed contract"
                        />
                        {create &&
                            <ModuleButton
                                btnIcon="edit"
                                style={{
                                    background: "orange",
                                    color: "white",
                                    marginLeft: 10
                                }}
                                onClick={(event) => handleCreateClick(event, cellValues.row, "variations/create")}
                                tip="Create contract variation"
                            />
                        }
                    </div>
                );
            },
        }
    ];

    const options = {
        page: page,
        handlePageChange: handlePageChange,
        pageSize: pageSize,
        handleSizeChange: handleSizeChange,
        rowsPerPageOptions: [10, 25, 100],
        rowCount: rowCount,
        autoHeight: true,
        height: 680
    };

    useEffect(() => {
        getContracts();
    }, [fetchTrigger]);


    if (read && data) {
        return (
            <>
                <DataTable
                    columns={columns}
                    data={data}
                    pagination={pagination}
                    options={options}
                    allowDelete={false}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    fetchTrigger={fetchTrigger}
                    setFetchTrigger={setFetchTrigger}
                />
                <ApiLoaderComponent status={loading.status} data={loading.data} />
            </>
        );
    } else {
        return <LoadingComponent />;
    }
}
