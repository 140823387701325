import React, { useState, useEffect } from "react";
import { AppHeaderComponent } from "@unity/components";
import { CanCreate, CanRead, CanReadAll, CanUpdate, CanModuleAdmin } from "../services/Permissions";
import ModuleTabs from "../common/ModuleTabs";
import ModuleFunctions from "../common/ModuleFunctions";
import PendingVariations from "../modules/PendingVariations";
import ActiveContracts from "../modules/ActiveContracts";
import ExpiredContracts from "../modules/ExpiredContracts";
import Settings from "../modules/Settings";

export default function Contracts({ route, context }) {
  const [tabValue, setTabValue] = useState(0);
  const [bus, setBus] = useState(false);

  const create = CanCreate("contracts");
  const read = CanRead("contracts");
  const edit = CanUpdate("contracts");
  const admin = CanModuleAdmin("contracts");
  const readAll = CanReadAll("contracts");

  const busChange = (data) => {
    setBus(data);
  };

  const handleChange = async (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    ModuleFunctions.getBusinesses({ change: busChange });
  }, []);


  const tabs = [
    { icon: "", label: "Active Contracts" },
    { icon: "", label: "Expired Contracts" },
    { icon: "", label: "Pending Variations" },
  ];

  if (admin) {
    tabs.push(
      { icon: "", label: "module settings" }
    )
  };


  return (
    <>
      <AppHeaderComponent
        context={context}
        theme={context.theme}
        name="contracts"
        spacer={true}
      />

      <ModuleTabs
        tabs={tabs}
        value={tabValue}
        setValue={setTabValue}
        handleChange={handleChange}
      />
      {tabValue === 0 && (// 2 if pending contracts added back in!
        <div style={{ paddingTop: 86 }}>
          <ActiveContracts
            context={context}
            create={create}
            read={read}
            edit={edit}
            admin={admin}
            readAll={readAll}
            bus={bus}
          />
        </div>
      )}
      {tabValue === 1 && (// 3 if pending contracts added back in!
        <div style={{ paddingTop: 86 }}>
          <ExpiredContracts
            context={context}
            create={create}
            read={read}
            edit={edit}
            admin={admin}
            readAll={readAll}
            bus={bus}
          />
        </div>
      )}
      {tabValue === 2 && (// 1 if pending contracts added back in!
        <div style={{ paddingTop: 86 }}>
          <PendingVariations
            context={context}
            create={create}
            read={read}
            edit={edit}
            admin={admin}
            readAll={readAll}
            bus={bus}
          />
        </div>
      )}
      {tabValue === 3 &&// 4 if pending contracts added back in!
        <div style={{ paddingTop: 86 }}>
          <Settings
            context={context}
          />
        </div>
      }
    </>
  );
}
