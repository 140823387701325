import React from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Autocomplete } from "@mui/material";
import ModuleFunctions from "../common/ModuleFunctions";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ServiceLines from "./ServiceLines";


export default function ContractForm(props) {
  const uneditable = ((props.type === 'view') || !props[props.type]);

  if(props.contract.customer_id) {
    ModuleFunctions.setClientChildren({
      id: props.contract.customer_id,
      clients: props.clients,
      contactsChange: props.contactsChange,
      sitesChange: props.sitesChange
    });
  }


  return (
    <div className="block">
      <div className="container-fluid">
        <div className="form-row">
          <div className="form-group col-lg-12">
            <h3>Contract</h3>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-lg-8">
            <TextField
              label="Name"
              name="name"
              type="text"
              value={props.contract.name || ""}
              error={props.errors && props.errors.name || false}
              helperText={ props.errors && props.errors.name || "" }
              onChange={(e) => uneditable || ModuleFunctions.handleChange({ name: e.target.name, value: e.target.value, change: props.contractChange })}
              size="small"
              fullWidth
              InputProps={{ readOnly: uneditable }}
            ></TextField>
          </div>
          <div className="form-group col-lg-4">
            <TextField
              label="Ref#"
              name="ref"
              type="text"
              value={props.contract.ref || ""}
              size="small"
              fullWidth
              disabled
            ></TextField>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-lg-12">
            <TextField
              label="Notes"
              name="notes"
              type="text"
              value={props.contract.notes || ""}
              multiline
              minRows={2}
              maxRows={5}
              error={props.errors && props.errors.notes || false}
              helperText={props.errors && props.errors.notes || ""}
              onChange={(e) => uneditable || ModuleFunctions.handleChange({ name: e.target.name, value: e.target.value, change: props.contractChange })}
              size="small"
              fullWidth
              InputProps={{ readOnly: uneditable }}
            ></TextField>
          </div>
        </div>

        <div className="form-row">
          {props.type !== "view" ? (
            <div className="form-group col-lg-4">
              <Autocomplete
                className="customer_autocomplete"
                options={props.clients}
                value={(props.clients.find((e) => e.client_id === props.contract.customer_id)) || {client_name: ""}}
                onChange={(evt, clientObj) => ModuleFunctions.handleClientChange({
                  client: clientObj,
                  change: props.contractChange,
                  contactsChange: props.contactsChange,
                  sitesChange: props.sitesChange
                })}
                getOptionLabel={(option) => option.client_name}
                getOptionSelected={(option, value) => option.client_id === value.client_id}
                disableClearable
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Customer Name"
                    error={props.errors && props.errors.customer_name || false}
                    helperText={ props.errors && props.errors.customer_name || "" }
                  />
                )}
              />
            </div>
          ) : (
            <div className="form-group col-lg-4">
              <TextField
                label="Customer Name"
                name="customer_name"
                type="text"
                value={props.contract.customer_name || ""}
                size="small"
                fullWidth
                InputProps={{ readOnly: uneditable }}
              />
            </div>
          )}
          {props.type !== "view" && props.contacts ? (
            <div className="form-group col-lg-4">
              <FormControl error={props.errors && props.errors.contact_id || false} fullWidth>
                <InputLabel>Contact Name</InputLabel>
                <Select
                  label="Contact Name"
                  name="contact_id"
                  value={props.contract.contact_id || ""}
                  onChange={(evt, personObj) => uneditable || ModuleFunctions.handlePersonChange({
                    event: evt,
                    element: personObj,
                    change: props.contractChange
                  })}
                  inputProps={{ readOnly: uneditable }}
                  size="small"
                >
                  {props.contacts && props.contacts.map((item, key) => {
                    return <MenuItem key={key} value={item.contact_id} personObj={item}>{item.contact_name}</MenuItem>
                  })}
                </Select>
                <FormHelperText>{props.errors && props.errors.contact_id || ""}</FormHelperText>
              </FormControl>
            </div>
          ) : (
            <div className="form-group col-lg-4">
              <TextField
                label="Contact Name"
                name="contact_name"
                value={props.contract.contact_name || ""}
                size="small"
                fullWidth
                InputProps={{ readOnly: uneditable }}
              />
            </div>
          )}
          {props.type !== "view" && props.sites ? (
            <div className="form-group col-lg-4">
              <FormControl error={props.errors && props.errors.site_id || false} fullWidth>
                <InputLabel>Company Address</InputLabel>
                <Select
                  label="Company Address"
                  name="site_id"
                  value={props.contract.site_id || ""}
                  onChange={(evt, siteObj) => uneditable || ModuleFunctions.handleSiteChange({
                    event: evt,
                    element: siteObj,
                    change: props.contractChange
                  })}
                  inputProps={{ readOnly: uneditable }}
                  size="small"
                >
                  {props.sites && props.sites.map((item, key) => {
                    return <MenuItem key={key} value={item.site_id} siteObj={item}>{item.site_name}</MenuItem>
                  })}
                </Select>
                <FormHelperText>{props.errors && props.errors.site_id || ""}</FormHelperText>
              </FormControl>
            </div>
          ) : (
            <div className="form-group col-lg-4">
              <TextField
                label="Company Address"
                name="site_name"
                value={props.contract.site_name || ""}
                size="small"
                fullWidth
                InputProps={{ readOnly: uneditable }}
              />
            </div>
          )}
        </div>

        <div className="form-row">
          <div className="form-group col-lg-6">
            <TextField
              label="Company Reg#"
              name="company_reg"
              type="text"
              value={props.contract.company_reg || ""}
              error={props.errors && props.errors.comnpany_reg || false}
              helperText={ props.errors && props.errors.company_reg || "" }
              onChange={(e) => uneditable || ModuleFunctions.handleChange({ name: e.target.name, value: e.target.value, change: props.contractChange })}
              size="small"
              fullWidth
              InputProps={{ readOnly: uneditable }}
            ></TextField>
          </div>
          <div className="form-group col-lg-6">
            <TextField
              label="Billing Email"
              name="billing_email"
              type="email"
              value={props.contract.billing_email || ""}
              error={props.errors && props.errors.billing_email || false}
              helperText={ props.errors && props.errors.billing_email || "" }
              onChange={(e) => uneditable || ModuleFunctions.handleChange({ name: e.target.name, value: e.target.value, change: props.contractChange })}
              size="small"
              fullWidth
              InputProps={{ readOnly: uneditable }}
            ></TextField>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-lg-4">
            <TextField
              label="Account#"
              name="account_no"
              type="text"
              value={props.contract.account_no || ""}
              size="small"
              fullWidth
              disabled
            ></TextField>
          </div>
          <div className="form-group col-lg-4">
            <TextField
              label="Email"
              name="customer_email"
              type="email"
              value={props.contract.customer_email || ""}
              error={props.errors && props.errors.customer_email || false}
              helperText={ props.errors && props.errors.customer_email || "" }
              onChange={(e) => uneditable || ModuleFunctions.handleChange({ name: e.target.name, value: e.target.value, change: props.contractChange })}
              size="small"
              fullWidth
              InputProps={{ readOnly: uneditable }}
            ></TextField>
          </div>
          <div className="form-group col-lg-4">
            <TextField
              label="Tel"
              name="customer_tel"
              type="tel"
              value={props.contract.customer_tel || ""}
              error={props.errors && props.errors.customer_tel || false}
              helperText={ props.errors && props.errors.customer_tel || "" }
              onChange={(e) => uneditable || ModuleFunctions.handleChange({ name: e.target.name, value: e.target.value, change: props.contractChange })}
              size="small"
              fullWidth
              InputProps={{ readOnly: uneditable }}
            ></TextField>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-lg-4">
            <FormControl error={props.errors && props.errors.business_unit || false} fullWidth>
              <InputLabel>Business Unit</InputLabel>
              <Select
                label="Business Unit"
                name="business_unit"
                value={props.contract.business_unit || ""}
                onChange={(e) => uneditable || ModuleFunctions.handleChange({ name: e.target.name, value: e.target.value, change: props.contractChange })}
                inputProps={{ readOnly: uneditable }}
                size="small"
              >
                {props.bus && props.bus.map((item, key) => {
                  return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                })}
              </Select>
              <FormHelperText>{props.errors && props.errors.business_unit || ""}</FormHelperText>
            </FormControl>
          </div>
          <div className="form-group col-lg-4">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                value={props.contract.start_date || ""}
                onChange={(value) => uneditable || ModuleFunctions.handleChange({ name: 'start_date', value: value, change: props.contractChange })}
                inputProps={{ readOnly: uneditable }}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    error={props.errors && props.errors.start_date || false}
                    helperText={ props.errors && props.errors.start_date || "" }
                    size="small"
                    fullWidth
                  />
                }
              />
            </LocalizationProvider>
          </div>
          <div className="form-group col-lg-4">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="End Date"
                value={props.contract.end_date || ""}
                onChange={(value) => uneditable || ModuleFunctions.handleChange({ name: 'end_date', value: value, change: props.contractChange })}
                inputProps={{ readOnly: uneditable }}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    error={props.errors && props.errors.end_date || false}
                    helperText={ props.errors && props.errors.end_date || "" }
                    size="small"
                    fullWidth
                  />
                }
              />
            </LocalizationProvider>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group-sm col-lg-12">
            <ServiceLines
              uneditable={uneditable}
              parent={props.contract}
              deals={props.deals}
              periods={props.periods}
              charges={props.charges}
              methods={props.methods}
              vats={props.vats}
              parentChange={props.contractChange}
              triggerChange={props.triggerChange}
            />
          </div>
        </div>
        
      </div>
    </div>
  );
}
