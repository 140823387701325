import React from "react";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "./services/AuthContext";
import Contracts from "./pages/index";
import ContractCreate from "./pages/create";
import ContractView from "./pages/view";
import ContractEdit from "./pages/edit";
import VariationCreate from "./pages/variations/create";
import VariationView from "./pages/variations/view";
import VariationEdit from "./pages/variations/edit";

export default function Root(props) {
  let context = { ...props };

  return (
    <AuthContext.Provider value={{ ...props }}>
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route path="/location" exact component={Location} />

                      {props.auth.access.contracts["A"] &&
                        props.auth.access.contracts["R"] && (
                          <Route
                            path="/contracts/index"
                            exact
                            component={(route) => (
                              <Contracts
                                route={route}
                                context={context}
                              />
                            )}
                          />
                        )
                      }

                      {props.auth.access.contracts["A"] &&
                        props.auth.access.contracts["R"] && (
                          <Route
                            path="/contracts/view/:id"
                            exact
                            component={(route) => (
                              <ContractView
                                route={route}
                                context={context}
                              />
                            )}
                          />
                        )
                      }

                      {props.auth.access.contracts["A"] &&
                        props.auth.access.contracts["R"] && (
                          <Route
                            path="/contracts/variations/view/:id"
                            exact
                            component={(route) => (
                              <VariationView
                                route={route}
                                context={context}
                              />
                            )}
                          />
                        )
                      }

                      {props.auth.access.contracts["A"] &&
                        props.auth.access.contracts["U"] && (
                          <Route
                            path="/contracts/edit/:id"
                            exact
                            component={(route) => (
                              <ContractEdit
                                route={route}
                                context={context}
                              />
                            )}
                          />
                        )
                      }

                      {props.auth.access.contracts["A"] &&
                        props.auth.access.contracts["U"] && (
                          <Route
                            path="/contracts/variations/edit/:id"
                            exact
                            component={(route) => (
                              <VariationEdit
                                route={route}
                                context={context}
                              />
                            )}
                          />
                        )
                      }

                      {props.auth.access.contracts["A"] &&
                        props.auth.access.contracts["C"] && (
                          <Route
                            path="/contracts/create"
                            exact
                            component={(route) => (
                              <ContractCreate
                                route={route}
                                context={context}
                              />
                            )}
                          />
                        )
                      }

                      {props.auth.access.contracts["A"] &&
                        props.auth.access.contracts["C"] && (
                          <Route
                            path="/contracts/variations/create"
                            exact
                            component={(route) => (
                              <VariationCreate
                                route={route}
                                context={context}
                              />
                            )}
                          />
                        )
                      }
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
