import React, { useEffect, useState } from "react";
import ContractForm from "../modules/ContractForm";
import { ContractsApi, AppHeaderComponent, LoadingComponent, ApiLoaderComponent } from "@unity/components";
import { useHistory } from "react-router";
import { CanCreate } from "../services/Permissions";
import ModuleFunctions from "../common/ModuleFunctions";

export default function ContractCreate(props) {
  const [contract, setContract] = useState(false);
  const [bus, setBus] = useState(false);
  const [clients, setClients] = useState(false);
  const [contacts, setContacts] = useState(false);
  const [sites, setSites] = useState(false);
  const [products, setProducts] = useState(false);
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState({ status: false });
  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if page needs to be re-rendered. The value doesn't matter, just the change!

  let history = useHistory();

  const create = CanCreate("contracts");

  const triggerChange = () => {
      setFetchTrigger(fetchTrigger + 1);
  };

  const busChange = (data) => {
    setBus(data);
  };

  const clientsChange = (data) => {
    setClients(data);
  };

  const contactsChange = (data) => {
    setContacts(data);
  };

  const sitesChange = (data) => {
    setSites(data);
  };

  const productsChange = (data) => {
    setProducts(data);
  };

  const contractChange = (data) => {
    setContract(prevState => ({
      ...prevState,
      ...data
    }));
  };

  const handleSubmit = async () => {
    setLoading({ status: true, data: "Creating your Contract, Please Wait...." });

    const res = await ContractsApi.saveContract(contract);

    console.log("Contract Save Res",res);

    if(res.success) {
      setLoading({ status: true, data: "Successfully created Contract" });
      setTimeout(() => {
        history.push(`/contracts/edit/${res.data.id}`);
        setLoading({ status: false });
      }, 3000);
    } else {
        if(res.errors) {
            setErrors(res.errors);
            setLoading({ status: true, data: "Validation Errors!, Please Wait...." });
            setTimeout(() => {
                setLoading({ status: false });
            }, 3000);
        } else {
            setLoading({ status: true, data: res.message });
            setTimeout(() => {
                history.push("/contracts/index");
                setLoading({ status: false });
            }, 3000);
        }
    }
  };

  useEffect(() => {
    ModuleFunctions.getBusinesses({ change: busChange });
    ModuleFunctions.getClients({ change: clientsChange });
    ModuleFunctions.getProducts({ change: productsChange });
  }, [fetchTrigger]);


  if (create && clients) {
    return (
      <>
        <AppHeaderComponent
          name="contracts"
          subpage="create"
          context={props.context}
          theme={props.context.theme}
          saveBtn={handleSubmit}
        />

        <div style={{ paddingTop: 1 }}>
          <ContractForm
            type="create"
            create={create}
            bus={bus}
            contract={contract}
            clients={clients}
            contacts={contacts}
            sites={sites}
            products={products}
            errors={errors}
            contractChange={contractChange}
            contactsChange={contactsChange}
            sitesChange={sitesChange}
          />
        </div>

        <ApiLoaderComponent
          status={loading.status}
          data={loading.data}
        />
      </>
    );
  } else {
    return <LoadingComponent />;
  }
}
